import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts';
import { TitleAndMetaTags } from '../components/TitleAndMetaTags/TitleAndMetaTags';
import { Section } from '../components/Section/Section';
import Icon, { IconBrand } from '../components/Icon/Icon';

function ContactsPage() {
	return (
		<Layout>
			<div className="main">
				<TitleAndMetaTags title="Contatti" pathname="contatti" />

				<Section>
					<div className="mw8 center ph3-ns pv5 pv0-l">
						<div className="cf ph3 ph2-ns tj">
							<h1>Contattaci</h1>
							<p>
								Collaborare con un creativo non è mai semplice ma vi invitiamo a
								provarci comunque, accantonate per un attimo le paure e i
								timori, sbriciolate l'imbarazzo che ci separa e contattateci
								pure.
							</p>

							<p>
								Se desiderate conoscere di più sulla nostra realtà, se avete
								domande o dubbi amletici che vi attanagliano; se volete
								collaborare con noi per donare al vostro brand la luce che
								merita o creare un prodotto che possa{' '}
								<b>sfamare sia il corpo che lo spirito</b> e per informazioni di
								qualsivoglia genere non scriveteci.
							</p>
						</div>
					</div>
				</Section>
			</div>
		</Layout>
	);
}

export default ContactsPage;
